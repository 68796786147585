(function ($) {
    $(document).ready(function(){

      var $body = $('body');

        /*
         * External links - add identifier
         */

        $("#container a[href*='http://'],#container a[href*='https://'], #main-container a[href*='http://'],#main-container a[href*='https://'],#bottom a[href*='http://'],#bottom a[href*='https://']")
            .not("a.no-ext-icon, a.btn, a:has(img), .social-links a, .social-icons a, #social-icons a")
            .filter(function() {
                return this.hostname && this.hostname !== location.hostname;
            })
            .append('<span class="element-invisible">(external link opens in a new window / tab)</span><span class="elink"></span>')
            .attr('title','external link opens in a new window / tab')
            .attr('target','_blank');

        // Empty P fix
        $('p').filter(function() {
            return ($.trim($(this).html().replace(/&nbsp;/g,'')) == '');
        }).remove();

        // if ($body.hasClass('panel-nicspubsearch')) {
        //
        //     $('.panel-col-first h3.pane-title').click(function(){
        //         ($(this).hasClass('active'))? $(this).removeClass('active') : $(this).addClass('active');
        //         $(this).next().find('.item-list').toggle();
        //     });
      /*
     * Adding in some progressive enhancement for search page facets
     */
        if (($body.hasClass('nics-search-page')) && (!$body.hasClass('no-facets'))) {
          // we have js, lets alter the markup around the facet h3's to add an
          // anchor - will target this (below):
          $('.dropdown-facet h3').each(function () {
            $(this).toggleClass('pane-title tighten');
            $(this).wrapInner("<a class='pane-title' href='#' role='button' aria-expanded='false'></a>");
          });

          // set up facet drop-downs behaviours
          $('.dropdown-facet h3 a').click(function () {
            ($(this).attr('aria-expanded', function (i, attr) {
              return attr == 'true' ? 'false' : 'true'
            }));
            $(this).toggleClass('active');
            $(this).parent().next('.item-list').toggle();
            return false;
          });

            var $facetsWrap = $('<div class="active-facets-wrap"></div>');
            var $facets = $('<ul class="active-facets" />');
            var facetsHeader = "";

            $('.facetapi-facetapi-links li').has('a.facetapi-active').each(function(){
                $(this).html($(this).html().replace(/\(\-\)/g,"<span class='remove' title='remove'>&times;</span>"));
                // Expand parents that contain active facets
                var parent = $(this).parents('div.item-list');
                parent.css("display", "block");
                // Expand any children of the active facet
                parent.find('div.item-list').css("display", "block");
                if (facetsHeader == "") {
                    facetsHeader = "";
                }
                var facetLabel = "";
                if ($(this).parent().hasClass('facetapi-facet-type')) {
                    facetLabel = ' of type ';
                } else if ($(this).parent().hasClass('facetapi-facet-field-category')) {
                    facetLabel = ' in category ';
                } else if ($(this).parent().hasClass('facetapi-facet-field-resource-organisation')) {
                    facetLabel = ' by author ';
                } else if ($(this).parent().hasClass('facetapi-facet-field-resource-publisher')) {
                    facetLabel = ' by publisher ';
                } else if ($(this).parent().hasClass('facetapi-facet-field-resource-year')) {
                    facetLabel = ' in year ';
                } else if ($(this).parent().hasClass('facetapi-facet-field-location')) {
                    facetLabel = ' in ';
                } else if ($(this).parent().hasClass('facetapi-facet-field-organisation-activity')) {
                    facetLabel = ' in activity ';
                } else if ($(this).parent().hasClass('facetapi-facet-field-organisation-type')) {
                    facetLabel = ' of organisation type ';
                } else if ($(this).parent().hasClass('facetapi-facet-field-publication-type')) {
                    facetLabel = ' of type ';
                } else if ($(this).parent().hasClass('facetapi-facet-field-publication-topics')) {
                    facetLabel = ' in ';
                } else if ($(this).parent().hasClass('facetapi-facet-field-publication-date')) {
                  facetLabel = ' in ';
                } else if ($(this).parent().hasClass('facetapi-facet-field-cyber-published-date')) {
                    facetLabel = ' published in ';
                } else if ($(this).parent().hasClass('facetapi-facet-field-topic')) {
                    facetLabel = ' of type ';
                } else if ($(this).parent().hasClass('facetapi-facet-field-manager-organisationfield-organisation-type')) {
                  facetLabel = ' of type ';
                } else if ($(this).parent().hasClass('facetapi-facet-field-consultation-response-type')) {
                  facetLabel = ' of type ';
                } else if ($(this).parent().hasClass('facetapi-facet-field-consultation-stage')) {
                  facetLabel = ' at stage ';
                }
                var $item = $(this).clone();
                $item.wrapInner('<span class="facetwrap" />').prepend(facetLabel);
                $item.find('.item-list').remove();
                $item.appendTo($facets);
            });

            $('.facetapi-facetapi-az-links li').has('a.facetapi-active').each(function(){
                // for the A-Z facet, clear any existing selection so that only one letter can be selected at a time
                if (facetsHeader == "") {
                  facetsHeader = "";
                }
                var facetLabel = "";
                if ($(this).parent().hasClass('facetapi-facet-first-letter')) {
                  facetLabel = ' beginning with the letter ';

                  var $item = $(this).clone();
                  $item.find('a').removeAttr('title');
                  $item.wrapInner('<span class="facetwrap facet-az" />').prepend(facetLabel);
                  $item.find('.item-list').remove();
                  $item.appendTo($facets);
                }
            });

            $facetsWrap.append(facetsHeader);
            $facets.appendTo($facetsWrap);
            $facetsWrap.appendTo('.current-search-item-result-count h2');
        }

      // WCAG ColorBox button labels for ADA compliance
      if($("#colorbox").length){
        $('#cboxPrevious').attr("aria-label", "Previous");
        $('#cboxNext').attr("aria-label", "Next");
        $('#cboxSlideshow').attr("aria-label", "Slideshow");
      }

    });

})(jQuery);
